import React from "react"

import { withStyles } from "@material-ui/core"

const Banner = React.memo(({ classes, image, text }) => (
  <div className={classes.banner}>
    <img src={image} className={classes.img} alt="Imagem de laboratório" />
    <div className={classes.container}>
      <h1 className={classes.mainText}>{text}</h1>
    </div>
  </div>
))

const styles = {
  banner: {
    height: 450,
    position: "relative",
    overflow: "hidden",
  },
  img: {
    filter: "brightness(0.5)",
    position: "absolute",
    display: "block",
    width: "100vw",
    height: " 100vh",
    objectFit: "cover",
    zIndex: -100,
  },
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
  mainText: {
    fontSize: "2em",
    padding: 10,
    textAlign: "center",
    color: "white",
  },
}

export default withStyles(styles)(Banner)
