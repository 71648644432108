import React from "react"

import { withStyles } from "@material-ui/core"
import Image from "../images/lens.jpg"
import ImageService from "../images/merk.png"
import Container from "../components/Container"
import ImgSection from "../components/ImgSection"
import ServicosContainer from "../containers/ServicosContainer"
import Banner from "../components/Banner"
import ContentDiv from "../components/ContentDiv"

const servicos = React.memo(({ classes }) => (
  <Container title="Serviços" page={1}>
    <div className={classes.root}>
      <Banner image={Image} text="Serviços" />
      <ContentDiv>
        <div className={classes.containerCard}>
          <div className={classes.containerText}>
            <p>
              Especializados em Equipamentos para Laboratório, oferecemos
              Serviços de Manutenção Preventiva, Manutenção Corretiva,
              Calibração de Diversas Marcas e Modelos.
            </p>
            <p>
              Tudo com Instrumentos Padrões Calibrados pela RBC (Rede Brasileira
              de Calibração), Possibilitando a Calibração de Equipamentos com
              Emissão de Certificados rastreáveis a RBC.
            </p>
          </div>
          <ServicosContainer />
        </div>
      </ContentDiv>
    </div>
    <ImgSection
      img={ImageService}
      imgToRight
      title="Merck - Purificação de Água"
      text="Nosso portfolio de purificação de água para laboratório oferece uma grande gama de sistemas e serviços de água pura e ultrapura projetados para cientistas que trabalham em laboratórios farmacêuticos, clínicos, acadêmicos, industriais, de pesquisa e governamentais em ambientes validados e não validados."
      list={[
        "Instalação  -  Manutenção Corretiva - Manutenção Preventiva",
        "Milli-Q - Água Ultrapura Tipo 1",
        "Elix - Água Pura Tipo 2",
        "Rios - Água OR Tipo 3",
        "AFS - Água CLRW (Lab. Clínico)",
      ]}
    />
  </Container>
))

const styles = {
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  containerCard: {
    paddingTop: 70,
    width: "100%",
    paddingRight: 15,
    paddingLeft: 15,
    marginRight: "auto",
    marginLeft: "auto",
  },
  containerText: {
    display: "flex",
    justifyContent: "center",
    textAlign: "left",
    flexDirection: "column",
    margin: 15,
    "& > p": {
      margin: 10,
    },
  },
}

export default withStyles(styles)(servicos)
