import React from "react"

import { withStyles } from "@material-ui/core"
import overlayImg from "../../images/about_overlay.png"

const ImgSection = React.memo(
  ({ classes, img, title, text, list, imgToRight }) => (
    <div className={classes.section}>
      <div className={classes.container}>
        <div className={classes.content}>
          <div style={imgToRight && { order: 2 }} className={classes.img}>
            {img && <img src={img} alt="Imagem de um laboratório" />}
          </div>
          <div className={classes.text}>
            <h2>{title}</h2>
            <p>{text}</p>
            {list && (
              <ul>
                {list.map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ul>
            )}
          </div>
        </div>
      </div>
    </div>
  )
)

const styles = {
  section: {
    position: "relative",
    zIndex: 99,
    backgroundImage: `url(${overlayImg})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "left",
    marginTop: 170,
    padding: 70,
  },
  container: {
    position: "relative",
    width: "100%",
    paddingRight: 15,
    paddingLeft: 15,
    marginRight: "auto",
    marginLeft: "auto",
    maxWidth: 1470,
  },
  content: {
    display: "flex",
    flexWrap: "wrap",
    marginRight: -20,
    marginLeft: -20,
    alignItems: "center",
    justifyContent: "space-between",
  },
  img: {
    position: "relative",
    width: "100%",
    paddingRight: 15,
    paddingLeft: 15,
    flex: "0 0 49%",
    maxWidth: "49%",
    overflow: "hidden",
    "& > img": {
      verticalAlign: "middle",
      borderStyle: "none",
      width: "100%",
    },
  },
  text: {
    flex: "0 0 50%",
    maxWidth: "50%",
    paddingLeft: 10,
    "& > h2": {
      color: "#005251",
      fontWeight: 600,
    },
    "& > ul": {
      padding: 0,
      "& > li": {
        display: "block",
      },
    },
  },
  "@media only screen and (max-width: 1200px)": {
    section: {
      marginTop: 100,
      backgroundImage: "none",
    },
    container: {
      maxWidth: 970,
    },
  },
  "@media (max-width: 800px)": {
    section: {
      paddingTop: 20,
      padding: 0,
      marginTop: 10,
      display: "flex",
      backgroundImage: `url(${overlayImg})`,
      overflow: "hidden",
    },
    content: {
      flexDirection: "column",
    },
    img: {
      maxWidth: "100%",
      paddingLeft: 45,
      paddingRight: 45,
    },
    text: {
      maxWidth: "100%",
      padding: "0px 20px",
    },
  },
}

export default withStyles(styles)(ImgSection)
