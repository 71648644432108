export const servicos = [
  {
    title: "Calibração:",
    description:
      "Calibramos praticamente todas as grandezas disponíveis com certificados de calibração que atende a qualquer auditoria/avaliação.",
  },
  {
    title: "Manutenção Preventiva:",
    description:
      "Com objetivo de Reduzir ou impedir falhas, essa manuteção é feita de forma sistemica e planejada de acordo com as especificações dos Equipamentos e das normas Seguidas",
  },
  {
    title: "Manutenção corretiva:",
    description:
      "Sempre ocorre na Falha de um equipamento, sendo sua urgência um requisito de muito importância. Nossa Empresa está capacitada para o rápido atendimento e diagnóstico. Diminuindo assim o dano causado pela falha do equipamento",
  },
  {
    title: "Treinamento:",
    description:
      "Hoje 70% das Manuteções corretivas são decorrentes do mau uso dos equipamentos, dessa forma desenvolvemos treinamentos sobre boas práticas de manuseio.",
  },
  {
    title: "Sanitização de ambientes:",
    description:
      "Sanitização de ambientes através de ozônio que é produzido a partir do oxigênio disponível no ambiente. Sendo ecologicamente correto pois não utiliza produtos químicos que podem danificar seus equipamentos nem deixa resíduos ou dejetos que possam contaminar, pessoas, animais e o próprio ambiente.",
    subDescription:
      "Eficiência comprovada contra o Covid19 superior a 99% com Laudo técnico.",
  },
]
