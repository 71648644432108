import React from "react"

import { withStyles, Paper, Typography } from "@material-ui/core"
import { servicos } from "./servicosUtils"

const ServicosContainer = React.memo(({ classes }) => (
  <div className={classes.root}>
    {servicos.map((item, index) => (
      <Paper key={index} className={classes.paper}>
        <Typography variant="h5" component="h3">
          {item.title}
        </Typography>
        <Typography component="p">{item.description}</Typography>
        {item.subDescription && (
          <Typography component="p">{item.subDescription}</Typography>
        )}
      </Paper>
    ))}
  </div>
))

const styles = {
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
  },
  paper: {
    padding: 15,
    margin: 15,
    width: "50%",
    flex: 1,
    flexBasis: "40%",
    "& > h3": {
      color: "#005251",
    },
    "& > p": {
      textAlign: "justify",
    },
  },
  "@media (max-width: 1240px)": {
    paper: {
      flexBasis: "100%",
    },
  },
}

export default withStyles(styles)(ServicosContainer)
